<template>
  <page-view class="design-com" :up-class="'design-com'">
    <div class="banner">
      <el-carousel trigger="click" :interval="2000" arrow="always" ref="carouselRef">
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <img v-lazy="item.image" width="100%" height="100%" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="texts">
      <div>国内外专业创意设计团队优选</div>
      <div>
        根据品牌需求匹配，量身优选设计团队/资深工作室。<br/>
        与国内众多优秀的专业设计师团队/资深工作室有着长期稳定的合作。<br/>
        与国际二十多个国家及地区著名的设计团队/资深工作室达成战略合作。
      </div>
    </div>

    <div class="twobanner">
      <el-carousel style="height: 100% !important;" trigger="click" arrow="always" :interval="2000" ref="carouselRef">
        <el-carousel-item style="height: 100% !important;" v-for="(item, index) in twobannerList" :key="index">
          <img v-lazy="item.image" width="100%" height="100%" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="design-show">
      <div class="tltle">设计公司展示</div>

      <div class="look-more">
        <div class="btn" @click="$router.push({ name: 'DesignComAll', params: { companyType: 2 } })">查看更多</div>
      </div>

      <div class="product-show-content">
        <div class="product-item" v-for="item in comList" :key="item.id" @click="toComIndex(item.syscompanyid)">
          <div class="item-name">{{ item.name }}</div>
          <div class="item-name">{{ item.addressDetail || '' }}</div>
          <div><img :src="item.cmsArticle2[0]?.image || item.cmsArticle1[0]?.image || item.cmsArticle4[0]?.image || item.logo" height="100%" width="100%" /></div>
        </div>
      </div>
      <div style="text-align: center;color: #a5a3a3;">百川智慧商展产业数字化平台</div>
    </div>

    <footer-com></footer-com>
  </page-view>
</template>
<script>

import {  mapActions } from "vuex";
export default {
  name: "DesignCom",
  data() {
    return {
      comList: [],
      bannerList:[],
      twobannerList:[]
    };
  },

  created() {
    this.selectSnDesignCom();
    this.getImgs()
    this.getListChoose();
  },
  methods: {
    ...mapActions(["setTheArticle", "setTheArticleList"]),
    async selectSnDesignCom() {
      try {
        let result = await this.$api.getBanner('PT00009');
        this.bannerList = result.itemList;
      } catch (error) {
        console.log(error);
      }
    },
    async getImgs() {
      let result = await this.$api.getBanner('PT00010');
      this.twobannerList = result.itemList;
    },

    async getListChoose() {
      let result = await this.$api.selectSn({
        bc_company_type: 2,
        current: 1,
        size: 6,
        s3:0//是优质企业
      });
      this.comList = result.data;
      console.log(this.comList[0].cmsArticle4[0]?.image);
    },
    toComIndex(syscompanyid) {
      const openRoute = this.$router.resolve({
        name: "ComIndex",
        params: { syscompanyid },
      });
      window.open(openRoute.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.design-com {
  background-color: #fff;

  .banner {
    :deep(.el-carousel) {
      .el-carousel__container {
        height: 21vw;

        .el-carousel__arrow {
          font-size: 28px;
          @include flexbox(row, center, center);
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  .texts {
    font-size: 2.6vw;
    color: rgb(41, 41, 41);
    font-weight: 600;
    width: 80%;
    margin: auto;
    padding: 7% 0%;

    >div:nth-child(2) {
      margin-top: 2%;
      font-weight: 400;
      font-size: 1.5vw;
      line-height: 2;
    }
  }

  .twobanner {
    height: 30vw;

    :deep(.el-carousel__container) {
      height: 100% !important;
    }
  }
  .design-show {
    padding: 5% 0% 4%;
    background-color: #F6F6F6;
    overflow: hidden;

    .tltle {
      font-size: max(16px,3.5vw);
      text-align: center;
    }

    .look-more {
      margin: 0 auto;
      color: #4C9C51;
      text-align: center;

      .btn {
        font-size: 12px;
        padding: 10px 0px;
        cursor: pointer;
      }
    }

    .product-show-content {
      width: 90%;
      margin: 3% auto;
      font-size: 1vw;
      color: #666666;
      white-space: nowrap;
      animation: liner 20s infinite linear;

      @keyframes liner {
        0% {
          transform: translateX(0%);
        }

        100% {
          transform: translateX(-90%);
        }
      }

      &:hover {
        animation-play-state: paused;
        -webkit-animation-play-state: paused;
      }

      .product-item {
        background: #ffffff;
        width: max(150px,30%);
        height: max(100px,20vw);
        padding-top: 2%;
        border-radius: 1vw;
        overflow: hidden;
        display: inline-flex;
        flex-direction: column;
        margin-right: 3%;
        text-align: center;
        cursor: pointer;
        .item-name{
          width: 90%;
          margin: auto;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        >div {
          padding: 1% 0%;
        }

        >div:nth-child(1) {
          font-weight: bold;
        }

        >div:nth-child(2) {
          padding-bottom: 6%;
          font-size: 0.9vw;
        }

        >div:nth-child(3) {
          flex: 1;
          overflow: hidden;
        }
      }
    }
  }
}
</style>


